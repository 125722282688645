<template>
  <div class="rank-common">
    <ul class="common-content">
      <li
        class="content-item"
        v-for="item in currentCommonRankList"
        :key="item.userId"
      >
        <div class="user">
          <el-avatar
            icon="el-icon-user-solid"
            class="avatar"
            :src="item.avatar"
          />
          <div class="user-info">
            <div class="username">{{ item.name }}</div>
            <div class="title">{{ item.title || '小小编程家' }}</div>
          </div>
        </div>
        <div class="rank-line"></div>
        <ul class="info">
          <li class="info-item">
            <img src="@/assets/image/rank-img/star-gray.png" alt="" />
            <p>{{ item.collectNum }}</p>
          </li>
          <li class="info-item">
            <img src="@/assets/image/rank-img/support-gray.png" alt="" />
            <p>{{ item.starNum }}</p>
          </li>
          <li class="info-item">
            <img src="@/assets/image/rank-img/view-gray.png" alt="" />
            <p>{{ item.viewNum }}</p>
          </li>
          <li class="info-item">
            <img src="@/assets/image/rank-img/code-gray.png" alt="" />
            <p>{{ item.codeLines || 0 }}</p>
          </li>
        </ul>
        <div class="tag"></div>
        <div class="rank-number">
          {{ item.ranking }}
        </div>
      </li>
      <li
        v-if="pageSize * currentPage > 47"
        class="content-item"
        style="visibility: hidden"
      ></li>
      <li
        v-if="pageSize * currentPage > 47 && commonRankList.length < 47"
        class="content-item"
        style="visibility: hidden"
      ></li>
    </ul>
    <el-pagination
      background
      :page-size.sync="pageSize"
      :page-sizes="[8, 16]"
      :total="commonRankList.length"
      :current-page.sync="currentPage"
      layout="total, sizes, prev, pager, next, jumper"
      class="rank-pagination"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  props: {
    commonRankList: Array,
  },
  data() {
    return {
      pageSize: 8,
      currentPage: 1,
    };
  },
  computed: {
    currentCommonRankList() {
      return this.commonRankList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rank-common {
  display: flex;
  flex-flow: column nowrap;
  .common-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .content-item:hover {
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    }
    .content-item {
      transition: all 0.2s;
      width: 224px;
      height: 122px;
      background-color: rgba(246, 246, 246, 1);
      margin-bottom: 20px;
      display: flex;
      flex-flow: column nowrap;
      padding: 10px;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      .user {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .avatar {
          width: 42px;
          height: 42px;
          margin-right: 12px;
        }
        .user-info {
          display: flex;
          flex-flow: column nowrap;
          font-size: 12px;
          color: #6a6a6a;
          font-weight: bold;
          line-height: 20px;
        }
      }
      .rank-line {
        width: 100%;
        height: 2px;
        background-color: #ddd;
        margin-bottom: 10px;
      }
      .info {
        display: flex;
        justify-content: space-between;
        .info-item {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          img {
            width: 14px;
            height: 14px;
            margin-bottom: 5px;
          }
          p {
            color: #6a6a6a;
            font-size: 12px;
          }
        }
      }
      .tag {
        position: absolute;
        border: 20px solid #5f80ff;
        top: 0;
        right: 0;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }
      .rank-number {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #fff;
        font-size: 12px;
      }
    }
  }
  .rank-pagination {
    margin: 20px 0 40px;
    align-self: flex-end;
  }
}
</style>